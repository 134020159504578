//this is going to store Firebase realtime database API code
import { db } from "./firebase";

//##########3 user API

//create an user and store it at users/id path (it's an asynchronous func)
export const doCreateUser = (id, username, email) =>
  db.ref(`users/${id}`).set({
    username,
    email
  });

  export const doStartTask = (id, dateid,isStarted,isEnded,time_joined,time_ended,date,tasks) =>
  db.ref(`tasks/${id}/${dateid}`).set({
    isStarted,
    isEnded,
    time_ended,
    time_joined,
    date,
    tasks

  });
  export const doSubmitTask = (id,dateid,isEnded,time_ended,tasks) =>
  db.ref(`tasks/${id}/${dateid}`).set({
    
    isEnded,
    time_ended,
    tasks

  });
//returns all users from firebase realtime db
export const onceGetUsers = () => db.ref("users").once("value");

export const doGetAnUnser = uid => db.ref(`users/${uid}`).once("value");
export const doGetTasks = (id,dateid) => db.ref(`tasks/${id}/${dateid}`).once("value");



// other APIs could come below

import React from "react";

const LandingPage = () => (
  <div>
    <h1>Its Your Mission!<span role="img" aria-label="rocket">🚀</span></h1>

    <h4>Should you choose to accept it?</h4>

    <h2>Yes - Login ✔️ </h2> 
    <h2>No - I'm Boring 👋</h2> 
   
<br></br>
   <h4> 
   We don't self-destruct.
     </h4> 
     <h4> 
     That's our client's Job🤷‍♂️
     </h4> 
   

    
  </div>
);

export default LandingPage;

import React from "react";
// import { Navbar, NavbarBrand, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { Nav, Navbar} from 'react-bootstrap';


import SignOutButton from "./SignOut";
import * as routes from "../constants/routes";

import AuthUserContext from "./AuthUserContext";

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? <NavigationAuth userInfo={authUser} /> : <NavigationNonAuth />
    }
  </AuthUserContext.Consumer>
);

const NavigationNonAuth = () => (
  

<Navbar collapseOnSelect  expand="lg">
<Navbar.Brand as={Link} to={routes.LANDING}><b>Unicographix </b>| Employee</Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav className="mr-auto d-block">
          <Nav.Item>
            <Nav.Link eventKey="1" as={Link} to={routes.SIGN_IN}>
              Sign In
            </Nav.Link>
          </Nav.Item>
          
          
        </Nav>
      </Navbar.Collapse>
    </Navbar>
);

export default Navigation;

const NavigationAuth = ({ userInfo }) => (
  



<Navbar collapseOnSelect  expand="lg">
<Navbar.Brand as={Link} to={routes.LANDING}><b>Unicographix </b>| Employee</Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse >
        <Nav className="ml-auto inline">
          <Nav.Item>
            <Nav.Link eventKey="1" as={Link} to={routes.HOME}>
              Home
            </Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
             <Nav.Link eventKey="2" as={Link} to={routes.ACCOUNT}>
              Account
            </Nav.Link>
          </Nav.Item> */}

          <Nav.Item className="ml-md-3">
          <SignOutButton />
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  
);

// const NavigationAuth = ({ userInfo }) => (
//   <ul>
//     {/* {console.log("NavigationAuth", userInfo)} */}
//     <li>
//       <Link to={routes.LANDING}>Landing</Link>
//     </li>
//     <li>
//       <Link to={routes.HOME}>Home</Link>
//     </li>
//     {/* disabling password changes/resets if user is logged in through facebook */}
//     {userInfo.providerData[0].providerId === "facebook.com" ? null : (
//       <li>
//         <Link to={routes.ACCOUNT}>Account</Link>
//       </li>
//     )}
//     <li>
//       <SignOutButton />
//     </li>
//   </ul>
// );

// const NavigationNonAuth = () => (
//   <ul>
//     <li>
//       <Link to={routes.LANDING}>Landing</Link>
//     </li>
//     <li>
//       <Link to={routes.SIGN_IN}>Sign In</Link>
//     </li>
//   </ul>
// );

import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import {getCurrentDate, getCurrentTime, getCurrentDateID} from '../utils/utils'

import withAuthorization from "./withAuthorization";
import { db } from "../firebase";


const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

const INITIAL_STATE = {
  
  email: "",
  password: "",
  error: null,
  users: null,
  username: "",
  
  loading: true,
  tasks: "",
  showingAlert: false
};
var uid;
var isStarted,t;
var Currentdate,dateid,Currenttime,startedTime,d,taskss,jointime,isjoined;
class HomePage extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      tasks : ''
    }
    
    this.updateInput = this.updateInput.bind(this);
   
    }
    
    
    updateInput(event){
    this.setState({tasks : event.target.value},() => {
      t = this.state.tasks ;
    });
     
    }
    
  state = { ...INITIAL_STATE };  
  

  
  componentDidMount() {
    // db.onceGetUsers().then(res => {
    //   this.setState({
    //     users: res.val()
    //   });
    // });

   

    const { loggedUser } = this.props;
    db.doGetAnUnser(loggedUser.uid).then(res => {
      
      uid = loggedUser.uid;
      this.setState({
        username: res.val().username,
        loading: false
      });
    });
    dateid = getCurrentDateID();

    db.doGetTasks(loggedUser.uid,dateid).then(res => {
      isStarted= res.val()!=null?false:true;
      startedTime = isStarted?'':res.val().time_joined;
      isjoined = res.val()!=null?res.val().isStarted:false;
      d = isStarted?'':res.val().date;

     
      this.setState({
        tasks: res.val()!=null?res.val().tasks:"",
        loading: false,
      });
    });

    

   
    
  }
  submitTasks() {
  
    // console.log(tasks)
    db.doStartTask(uid,dateid ,true,true,startedTime,getCurrentTime(),d,t)
    .then(() => {
      alert("Your task has been updated...🚀")
    })
    .catch(error => {
      this.setState(byPropKey("error", error));
      this.timer(); //show alert message for some seconds
    });
    
    // event.preventDefault();
  }


  startDay(){
    
    dateid = getCurrentDateID();
    Currentdate= getCurrentDate();
    Currenttime = getCurrentTime();

   
    db.doStartTask(uid,dateid ,true,false,Currenttime,"",Currentdate,"")
    .then(() => {
      window.location.reload(false);
    })
    .catch(error => {
      this.setState(byPropKey("error", error));
      this.timer(); //show alert message for some seconds
    });
  }
  

  render() {
    const { users, username, loading } = this.state;
    // console.log("dasdf", this.props.loggedUser);
    const {  tasks, error, showingAlert } = this.state;

    const isInvalid = tasks === "" ;
    

    

    return (
      <div className="text-center">

        
    {!loading && <h3 className="">Hello👋 {username}! </h3>}
        <h4>
          Welcome to Unico Family {this.state.date}
        </h4>
    { !isjoined?" ": <h5>You have joined at {startedTime}</h5>}

       { isjoined?" ": <div> <p style={{ marginTop: "10px" }}>
          Lets start your day with unico
        </p><h1>🚀</h1>
        <Button color="success" disabled={uid===undefined || !isStarted} type="submit"  onClick={this.startDay}>
              Start 
            </Button>
            </div>}
        {showingAlert && (
          <Alert color="danger" onLoad={this.timer}>
            {error.message}
          </Alert>
        )}
        { !isjoined?" ": <div>
        <FormGroup>
            <Label for="tasks">Completed Tasks</Label>
            <br/>
            <textarea
              type="text"
              name="tasks"
              id="tasks"
              placeholder="Enter the tasks completed today"
              value={tasks}
              onChange={this.updateInput
              }
            />
          </FormGroup>
          <div className="text">
            <Button color="danger" disabled={isInvalid} type="submit" onClick={this.submitTasks}>
              Submit
            </Button>
          </div>
          {showingAlert && (
          <Alert color="danger" onLoad={this.timer}>
            {error.message}
          </Alert>
        )}
          </div>}
          
        {/* {!!users && <UserList users={users} />} */}

      </div>
    );
   
  }
}

// const UserList = ({ users }) => (
//   <div>
//     {console.log("users", users)}
//     <h2>List of Usernames of Users</h2>
//     <p>(Saved on Sign Up in Firebase Database)</p>

//     {Object.keys(users).map(key => (
//       <div key={key}>{users[key].username}</div>
//     ))}
//   </div>
// );

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(HomePage); //grants authorization to open endpoint if an user is signed in

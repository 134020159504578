export function getCurrentDate(separator='/'){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${date}${separator}${month<10?`0${month}`:`${month}`}${separator}${year}`
    }

    export function getCurrentDateID(separator=''){

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        
        return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
        }

        function checkTime(i) {
            if (i < 10) {
              i = "0" + i;
            }
            return i;
          }
        export function getCurrentTime(separator=':'){

            let today = new Date()
            let hrs = today.getHours();
            let min = today.getMinutes();
            let sec = today.getSeconds();
            hrs = checkTime(hrs);
            min = checkTime(min);
            sec = checkTime(sec);
            return `${hrs}${separator}${min}${separator}${sec}`
            }

